import { PoiBuilding } from '@interfaces/building'
import { ExtendedAreaUnit } from '@interfaces/common'
import { ContactInfo } from '@interfaces/contact'
import { CdnImage, IImage, ImageStatus } from '@interfaces/images'
import { EnrichedRental, IErrorForm, RawScrape, Rental, RentalForm } from '@interfaces/rental'
import { AbstractUnitType, ExtraUnits } from '@lib/enum'
import { getLogger, LoggerDetails } from '@property-scout/common-logger'
import { validateContacts } from '@property-scout/core-ui/lib/Forms/ContactEditor/validateContacts'
import contactValidation from '@property-scout/locale-strings/lib/locales/en/contact-validation.json'
import { translate } from '@property-scout/locale-strings/lib/translate'
import IMAGE_TAGS from '@property-scout/meta/lib/listing-image-tags.json'
import { AreaUnit, MinLease, NumberOfBed, PostBy, UnitTypes } from '@property-scout/shared-enum'
import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'

import { abstractToUnitTypeMap, bedRoomsToAbstractUnitTypeMap } from './constants'
import { FormValidationResult } from './types'

const logger = getLogger({ filename: __filename, category: 'rental-form' })

const isEmptyValue = (value: unknown): boolean => {
  if (typeof value === 'object') {
    return isEmpty(value)
  }

  return !value
}

const isEmptyField = (value: unknown, error: string | boolean | any[]): boolean | undefined => {
  if (error) {
    return false
  } else if (!isEmptyValue(value)) {
    return true
  }

  return
}

const createAbstractUnitType = (rental: Rental): AbstractUnitType | null => {
  const { unitType, numberBedrooms } = rental
  if (unitType === UnitTypes.Studio && numberBedrooms === NumberOfBed.OneBed) {
    return AbstractUnitType.studio
  }

  return bedRoomsToAbstractUnitTypeMap[numberBedrooms] || null
}

export const translatePhoneObject = (obj) => {
  if (!obj) {
    return []
  }

  return obj.map((item) => {
    return {
      countryCode: item.code,
      phone: item.number,
    }
  })
}

export const normalizeTextNumber = (dirtyNumber?: string) => {
  if (!dirtyNumber) {
    return ''
  }
  const nonCommaNum = dirtyNumber.replace(/,/g, '')
  const num = _.toNumber(nonCommaNum)
  if (_.isNaN(num)) {
    return ''
  }
  const numText = num.toLocaleString('en-US')

  return nonCommaNum.endsWith('.') ? `${numText}.` : numText
}

const normalizeAreaSize = (area: number | string | null | undefined, unit?: ExtendedAreaUnit): number => {
  const size = _.toNumber(area)
  switch (unit) {
    case ExtraUnits.Sqrai: {
      return size * 1600
    }
    case ExtraUnits.Sqwah: {
      return size * 4
    }
    default:
      return size
  }
}
export const enrichContact = (
  rental: RentalForm,
  contactInfos: ContactInfo[],
  fieldKey: string,
  rootKey: keyof RentalForm,
  rawScrape: RawScrape[],
  transformContact?: (field: any) => any,
) => {
  if (!rental[rootKey]) {
    return contactInfos
  }
  const existedContactIds = ((rental.contact ? rental.contact.info : []) || [])
    .filter((c) => c.key === fieldKey)
    .map((c) => c.id)
  const contactItems = !!transformContact
    ? transformContact(rental[rootKey])
    : rental[rootKey].filter((value: any) => !!value)
  contactItems.forEach((value: any) => {
    const contact = rental.contact || { info: [] }

    // ignore if contact already exists in rawscrape or shared contact
    if (
      !rawScrape.find((item) => item.key === fieldKey && item.value === value) &&
      !contact.info.find((item) => item.key === fieldKey && item.value === value)
    ) {
      rawScrape.push({
        key: fieldKey,
        value,
      })
    }
  })

  return [
    ...contactInfos,
    ...contactItems.map((value: any, index: number) => ({
      key: fieldKey,
      value,
      ...(index < existedContactIds.length && {
        id: existedContactIds[index],
      }),
    })),
  ]
}

export const normalizeNumber = (dirtyNumber: string, floorNumber = true) => {
  const nonCommaNum = _.trim(`${dirtyNumber}`).replace(/,/g, '')

  const number = _.toNumber(nonCommaNum)

  return floorNumber ? _.floor(number) : number
}

export function enrichRental(rental: RentalForm): EnrichedRental {
  const { floorSizeUnit, landAreaUnit, ...rest } = rental
  const updatedRental = { ...rest }
  updatedRental.enhancerVersion = 0
  const rawScrape = [...rental.rawScrape]

  const postBy = rental.postBy
  if (postBy === 'cobroker') {
    updatedRental.postBy = PostBy.agent
  }

  updatedRental.rawScrape = rawScrape

  updatedRental.floorSize = normalizeAreaSize(updatedRental.floorSize, floorSizeUnit)
  if (!!updatedRental.floorSize) {
    updatedRental.floorSizeSource = updatedRental.floorSize
  } else {
    updatedRental.floorSizeSource = 0
  }

  if (rental.landArea === '') {
    updatedRental.landArea = null
  } else {
    updatedRental.landArea = normalizeAreaSize(updatedRental.landArea, landAreaUnit)
  }

  if (rental.abstractUnitType) {
    const { unitType, numberBedrooms } = abstractToUnitTypeMap[rental.abstractUnitType]
    updatedRental.unitType = unitType
    updatedRental.numberBedrooms = numberBedrooms

    updatedRental.numberBedroomsSource = numberBedrooms

    delete updatedRental.abstractUnitType
  } else {
    updatedRental.numberBedroomsSource = null
  }

  // trim all 0 and spaces to make sure avoid some one typing 00 or '0 0' in input
  if (!rental.floorLevel || !_.trim(rental.floorLevel, '0 ')) {
    updatedRental.floorLevelSource = ''
    updatedRental.floorLevel = null
  } else {
    updatedRental.floorLevelSource = _.trim(rental.floorLevel)
  }

  if (!!rental.monthlyPriceMin12Months) {
    updatedRental.minLeasePeriod = MinLease.TwelveMonths
    delete updatedRental.monthlyPriceMin12Months
    updatedRental.monthlyPriceMin12Months = normalizeNumber(rental.monthlyPriceMin12Months as string)
  } else {
    updatedRental.monthlyPriceMin12Months = null
  }

  if (!!rental.monthlyPriceMin6Months) {
    updatedRental.minLeasePeriod = MinLease.SixMonths
    delete updatedRental.monthlyPriceMin6Months
    updatedRental.monthlyPriceMin6Months = normalizeNumber(rental.monthlyPriceMin6Months as string)
  } else {
    updatedRental.monthlyPriceMin6Months = null
  }

  if (!!rental.monthlyPriceMin3Months) {
    updatedRental.minLeasePeriod = MinLease.ThreeMonths
    delete updatedRental.monthlyPriceMin3Months
    updatedRental.monthlyPriceMin3Months = normalizeNumber(rental.monthlyPriceMin3Months as string)
  } else {
    updatedRental.monthlyPriceMin3Months = null
  }

  if (!!rental.monthlyPriceMin1Month) {
    updatedRental.minLeasePeriod = MinLease.Monthly
    delete updatedRental.monthlyPriceMin1Month
    updatedRental.monthlyPriceMin1Month = normalizeNumber(rental.monthlyPriceMin1Month as string)
  } else {
    updatedRental.monthlyPriceMin1Month = null
  }

  if (!!rental.salePrice) {
    // normalize data of sale price before send
    updatedRental.salePrice = normalizeNumber(rental.salePrice as string)
  }

  if (rental.camFees) {
    // normalize data of cam fees before send
    updatedRental.camFees = normalizeNumber(rental.camFees as string, false)
  }

  if (updatedRental.contact) {
    updatedRental.contact = {
      ...updatedRental.contact,
      type_src: postBy === PostBy.landlord ? PostBy.landlord : PostBy.agent,
    }
  }

  return { ...updatedRental, postBy_src: postBy } as EnrichedRental
}

export function initForm(rental?: Rental): RentalForm {
  // Not prefill data if listing source is facebook or cobroker_line
  // Ref: https://flexstay.atlassian.net/browse/FS-944

  const rentalCopy: Rental = rental ? { ...rental } : ({} as Rental)
  const isWebsiteRental = rentalCopy?.systemSource === 'website'

  let prioritizeFeaturedCdnImageArray: CdnImage[] = []

  const disablePrefill = rentalCopy && rentalCopy.listingSource?.includes('cobroker_fb')

  const rentalIsWebsiteListing = rentalCopy && isWebsiteRental
  const takePrefilledData = (rentalCopy && !disablePrefill) || rentalIsWebsiteListing

  const bathRooms = takePrefilledData ? rentalCopy.numberBathrooms : null
  const floorLevel = takePrefilledData ? rentalCopy.floorLevel || null : null

  // Prefill postBy is cobroker if listing source start with cobroker_
  // Ref: https://flexstay.atlassian.net/browse/FS-944
  const prePostBy =
    rentalCopy && rentalCopy.listingSource && rentalCopy.listingSource.startsWith('cobroker_') ? 'cobroker' : null
  const disableChangePostBy = !!prePostBy
  const availPostBy = prePostBy === 'cobroker' ? ['cobroker'] : ['landlord', 'agent']
  const formRental: RentalForm = {} as RentalForm
  const abstractUnitType = takePrefilledData ? createAbstractUnitType(rentalCopy) : null
  const propertyType = takePrefilledData ? rentalCopy.propertyType : null
  const propertyView = rentalCopy?.propertyView || null
  const floorSize = takePrefilledData ? rentalCopy.floorSize : undefined

  if (rentalCopy?.cdnImages && isWebsiteRental) {
    rentalCopy?.cdnImages.forEach((image) => {
      if (image.status === ImageStatus.Featured) {
        prioritizeFeaturedCdnImageArray?.unshift(image)
      } else {
        prioritizeFeaturedCdnImageArray?.push(image)
      }
    })
  } else {
    prioritizeFeaturedCdnImageArray = rentalCopy?.cdnImages || []
  }

  if (takePrefilledData) {
    if (!!rentalCopy.monthlyPriceMin12Months) {
      formRental.monthlyPriceMin12Months = normalizeTextNumber(`${rentalCopy.monthlyPriceMin12Months}`)
    }
    if (!!rentalCopy.monthlyPriceMin6Months) {
      formRental.monthlyPriceMin6Months = normalizeTextNumber(`${rentalCopy.monthlyPriceMin6Months}`)
    }
    if (!!rentalCopy.monthlyPriceMin3Months) {
      formRental.monthlyPriceMin3Months = normalizeTextNumber(`${rentalCopy.monthlyPriceMin3Months}`)
    }
    if (!!rentalCopy.monthlyPriceMin1Month) {
      formRental.monthlyPriceMin1Month = normalizeTextNumber(`${rentalCopy.monthlyPriceMin1Month}`)
    }
  }

  const {
    monthlyPriceMin12Months,
    monthlyPriceMin6Months,
    monthlyPriceMin3Months,
    monthlyPriceMin1Month,
    ...rentalExcludePrice
  } = rentalCopy

  return {
    ...rentalExcludePrice,
    ...formRental,
    postBy: prePostBy,
    rentedOut: null,
    tenure: null,
    relevance: null,
    noPropertyListing: false,
    disableChangePostBy,
    availPostBy,
    abstractUnitType,
    propertyType,
    propertyView,
    numberBathrooms: bathRooms,
    poiBuildingObj: rentalCopy ? rentalCopy.poiBuilding : ({} as PoiBuilding),
    buildingText: `${_.get(rentalCopy, 'poiBuilding.name.en', '')} ${_.get(rentalCopy, 'poiBuilding.name.th', '')}`,
    lat: rentalCopy ? rentalCopy.gpsLat : '',
    lng: rentalCopy ? rentalCopy.gpsLong : '',
    featuredImage: isWebsiteRental ? prioritizeFeaturedCdnImageArray?.[0] : null,
    floorLevel,
    floorSize,
    floorSizeUnit: AreaUnit.SQM,
    cdnImages:
      rentalCopy && rentalCopy.cdnImages
        ? (prioritizeFeaturedCdnImageArray?.map((img) => ({
            ...img,
            badImage: false,
            attractiveImage: false,
            roomType: '',
            exterior: '',
            tags: IMAGE_TAGS.reduce((tagObj, tag) => {
              return {
                ...tagObj,
                [tag.name]: false,
              }
            }, {}),
          })) as IImage[])
        : [],
    uploadedImages: [],
    unpublishedImages: [],
  }
}

function validateContactInfo(form: RentalForm) {
  const contacts = (form.contact ? form.contact : {}).info || []

  return validateContacts(
    contacts,
    (i18nLabel, data) => {
      return translate({
        locale: 'en',
        dictionary: { en: contactValidation },
        data: data as Record<string, string | number>,
        i18nLabel,
      })
    },
    {
      oneOfRequired: true,
      skipBitrixUrlCheck: false,
    },
  )
}

export const checkCoordinates = (form: RentalForm, field: 'lat' | 'lng') => {
  const latOrLngParedToFloat = Number.parseFloat(form[field] as string)
  if (field === 'lat') {
    return !form[field] || Number.isNaN(latOrLngParedToFloat) || latOrLngParedToFloat < 5 || latOrLngParedToFloat > 21
  }

  return !form[field] || Number.isNaN(latOrLngParedToFloat) || latOrLngParedToFloat < 97 || latOrLngParedToFloat >= 106
}

export function formValidation(
  form: RentalForm,
  error: IErrorForm,
): { valResult: FormValidationResult; invalidContacts: string[] } {
  const needValidateRentalInfo = form && form.relevance === 'relevant' && form.tenure !== 'other'
  const isInvalidLat = checkCoordinates(form, 'lat')
  const isInvalidLng = checkCoordinates(form, 'lng')

  const { errors: contactsErrors, invalidValues: invalidContacts } = validateContactInfo(form)

  const rentedOut = isEmptyField(form.rentedOut, error.rentedOut)
  const tenure = isEmptyField(form.tenure, error.tenure)
  const relevance = isEmptyField(form.relevance, error.relevance)
  const postBy = isEmptyField(form.postBy, error.postBy)
  const valResult: FormValidationResult = {
    rentedOut: rentedOut === undefined ? rentedOut : !!form.rentedOut,
    tenure: tenure === undefined ? tenure : !!form.tenure,
    relevance: relevance === undefined ? relevance : !!form.relevance,
    floorUnit: isEmptyValue(form.floorUnit) || !!form.floorSize,
    postBy: postBy === undefined ? postBy : !!form.postBy,
    building: isEmptyField(form.poiBuildingObj, error.building!),
    ...contactsErrors,
  }

  if (needValidateRentalInfo) {
    valResult.lat = !isInvalidLat
    valResult.lng = !isInvalidLng
    valResult.propertyType = !!form.propertyType

    if (form.propertyType !== 'land') {
      valResult.abstractUnitType = !!form.abstractUnitType
      valResult.numberBathrooms = !!form.numberBathrooms
      valResult.floorSize = !isEmptyValue(form.floorSize)
    } else {
      valResult.landArea = !isEmptyValue(form.landArea)
    }

    if (form.tenure === 'rent' || form.tenure === 'rentsell') {
      valResult.price =
        !!form.monthlyPriceMin12Months ||
        !!form.monthlyPriceMin6Months ||
        !!form.monthlyPriceMin3Months ||
        !!form.monthlyPriceMin1Month
          ? true
          : false
    }

    if (form.tenure === 'sell' || form.tenure === 'rentsell') {
      valResult.salePrice = !!form.salePrice
    }
  }

  return { valResult, invalidContacts }
}

export const getScrapByKey = (rawScrap: RawScrape[], key: RawScrape['key']) => {
  const rawScrapByKey = (rawScrap || []).find((scrap) => scrap.key === key)

  return rawScrapByKey && rawScrapByKey.value
}

export const hasError = (errorForm: IErrorForm) => {
  const formInfo = { ...errorForm }

  return Object.keys(formInfo).some((key) => formInfo[key as keyof typeof formInfo])
}

export const hasInfoError = (errorForm: IErrorForm) => {
  const { cdnImages, featuredImage, uploadedImages, ...rest } = errorForm

  return Object.keys(rest).some((key) => rest[key as keyof typeof rest])
}

export const hasImageError = (errorForm: IErrorForm) =>
  errorForm.cdnImages && errorForm.cdnImages.some((img) => img.picType)

export function checkFormError(form: RentalForm, checkImage = true): IErrorForm {
  const needCheckImage =
    form.relevance === 'relevant' &&
    form.tenure !== 'other' &&
    ['landlord', 'cobroker'].includes(form.postBy ?? '') &&
    checkImage
  const isInvalidLat = checkCoordinates(form, 'lat')
  const isInvalidLng = checkCoordinates(form, 'lng')

  let error: IErrorForm = {
    tenure: !form.tenure && 'Please select the tenure',
    relevance: !form.relevance && 'Please select the relevancy',
    postBy: !form.postBy && 'Please select the posted by',
    rentedOut: !form.rentedOut && 'Please select the availability',
    featuredImage: checkImage && !form.featuredImage && 'Please choose one featured image',
    cdnImages: !needCheckImage
      ? false
      : form.cdnImages.some((img) => img.imageType !== 'badImage' && !img.roomType && !img.exterior) &&
        form.cdnImages.map((img, index) => {
          return {
            picType: img.imageType !== 'badImage' && !img.roomType && !img.exterior,
            index,
          }
        }),
    uploadedImages: needCheckImage
      ? form.uploadedImages.some((img) => img.imageType !== 'badImage' && !img.roomType && !img.exterior) &&
        form.uploadedImages.map((img, index) => {
          return {
            picType: img.imageType !== 'badImage' && !img.roomType && !img.exterior,
            index,
          }
        })
      : false,
  }

  if (form && form.relevance === 'relevant' && form.tenure !== 'other') {
    if (isInvalidLat) {
      error.lat = 'Please check the latitude'
    }
    if (isInvalidLng) {
      error.lng = 'Please check the longitude'
    }
    error.propertyType = !form.propertyType && 'Please select the property type'
    if (form.propertyType !== 'land') {
      error.floorSize = !form.floorSize && 'Please check the floor size'
      error.abstractUnitType = !form.abstractUnitType && 'Please Check the unit type'
      error.numberBathrooms = !form.numberBathrooms && 'Please check the bathrooms'
    } else {
      error.landArea = !form.landArea && 'Please check land area'
    }

    if (form.tenure === 'rent' || form.tenure === 'rentsell') {
      const isValidPrice =
        form &&
        (!!form.monthlyPriceMin12Months ||
          !!form.monthlyPriceMin6Months ||
          !!form.monthlyPriceMin3Months ||
          !!form.monthlyPriceMin1Month)
      error.price = !isValidPrice && 'Please check the rental price'
    }

    if (form.tenure === 'sell' || form.tenure === 'rentsell') {
      const isValidSalePrice = form && !!form.salePrice
      error.salePrice = !isValidSalePrice && 'Please check the sales price'
    }
  }

  const { errors: contactsErrors } = validateContactInfo(form)

  error = {
    ...error,
    ...contactsErrors,
  }

  logger.info('Error check', error as unknown as LoggerDetails)

  return error
}
